<template>
	<div style="padding-bottom: 30px;">
		<div class="title">设置用户信息</div>
		<van-field
      v-model="nickname"
      type="text"
      label="昵称"
      :label-width="100"
      disabled
    />
		<van-field
      v-model="mobile"
      type="text"
      label="手机号"
      :label-width="100"
      disabled
    />
		<van-field
      v-model="info.first_name_cn"
      type="text"
      label="名（中国）"
			placeholder="请输入"
      :label-width="100"
    />
		<van-field
      v-model="info.last_name_cn"
      type="text"
      label="姓（中国）"
			placeholder="请输入"
      :label-width="100"
    />
		<van-field
      v-model="info.first_name_en"
      type="text"
      label="名（非中国）"
			placeholder="请输入"
      :label-width="100"
    />
		<van-field
      v-model="info.last_name_en"
      type="text"
      label="姓（非中国）"
			placeholder="请输入"
      :label-width="100"
    />
		<van-field
			:value="info.birthday"
			is-link
			readonly
			label="生日"
			placeholder="请选择生日"
			@click="bshow = true"
		/>
		<van-popup v-model="bshow" round position="bottom">
			<van-datetime-picker
				type="date"
				@cancel="bshow = false"
				@confirm="bOnConfirm"
				:min-date="minDate"
				:max-date="maxDate"
			/>
		</van-popup>
		<van-field
			:value="info.annual_income"
			is-link
			readonly
			label="年收入"
			placeholder="请选择年收入"
			@click="ashow = true"
		/>
		<van-popup v-model="ashow" round position="bottom">
			<van-picker
				:columns="alist"
				@cancel="ashow = false"
				@confirm="aOnConfirm"
				show-toolbar
			/>
		</van-popup>
		<van-field
			:value="ptext"
			is-link
			readonly
			label="职业"
			placeholder="请选择职业"
			@click="pshow = true"
		/>
		<van-popup v-model="pshow" round position="bottom">
			<van-picker
				:columns="plist"
				@cancel="pshow = false"
				@confirm="pOnConfirm"
				show-toolbar
			/>
		</van-popup>
		<van-field
			:value="otext"
			is-link
			readonly
			label="职位"
			placeholder="请选择职位"
			@click="oshow = true"
		/>
		<van-popup v-model="oshow" round position="bottom">
			<van-picker
				:columns="olist"
				@cancel="oshow = false"
				@confirm="oOnConfirm"
				show-toolbar
			/>
		</van-popup>
		<van-field
      v-model="info.email"
      type="text"
      label="邮件地址"
			placeholder="请输入邮件地址"
      :label-width="100"
    />
		<van-field
			:value="itext"
			is-link
			readonly
			label="证件类型"
			placeholder="请选择证件类型"
			@click="ishow = true"
		/>
		<van-popup v-model="ishow" round position="bottom">
			<van-picker
				:columns="ilist"
				@cancel="ishow = false"
				@confirm="iOnConfirm"
				show-toolbar
			/>
		</van-popup>
		<van-field v-model="info.front" label="正面照片">
      <template #input>
        <van-image 
          width="100" 
          height="100" 
          :src="image_preview1"
          style="vertical-align: middle;" 
          @click="qrcodeUpload(1)"/>
      </template>
    </van-field>
		<van-field v-model="info.back" label="反面照片">
      <template #input>
        <van-image 
          width="100" 
          height="100" 
          :src="image_preview2"
          style="vertical-align: middle;" 
          @click="qrcodeUpload(2)"/>
      </template>
    </van-field>
		<!-- <div class="zj">
			<div class="zjname">正面照片</div>
			<van-uploader
				v-model="fileList"
				:max-count="1"
				:preview-size="80"
				:after-read="afterRead"
			/>
			<van-image 
				width="100" 
				height="100" 
				:src="info.front" 
				fit="contain" 
			/>
		</div>
		<div class="zj">
			<div class="zjname">反面照片</div>
			<van-uploader
				v-model="fileList2"
				:max-count="1"
				:preview-size="80"
				:after-read="afterRead2"
			/>
			<van-image 
				width="100" 
				height="100" 
				:src="info.back" 
				fit="contain" 
			/>
		</div> -->
		
		
		<van-field
      v-model="info.number"
      type="text"
      label="证件号码"
			placeholder="请输入证件号码"
      :label-width="100"
    />
		<van-field
			:value="ctext"
			is-link
			readonly
			label="证件所属国家"
			placeholder="请选择国家"
			@click="cshow = true"
		/>
		<van-popup v-model="cshow" round position="bottom">
			<van-picker
				:columns="clist"
				@cancel="cshow = false"
				@confirm="cOnConfirm"
				show-toolbar
			/>
		</van-popup>
		<van-field
			:value="info.expiry_date"
			is-link
			readonly
			label="证件有效期"
			placeholder="请选择证件有效期"
			@click="eshow = true"
		/>
		<van-popup v-model="eshow" round position="bottom">
			<van-datetime-picker
				type="date"
				@cancel="eshow = false"
				@confirm="eOnConfirm"
				:min-date="minDate"
				:max-date="maxDate"
			/>
		</van-popup>
    
    <div class="common_block">
      <van-button type="warning" block @click="postPapers">{{translate('submit_amount_options')}}</van-button>
    </div>
	</div>
</template>

<script>
	export default {
		name: 'personal_phone2',
		data() {
			return {
				imgtype:1,
				image_preview1: this.$axios.defaults.resourceURL + "images/img_upload.png",
        image_preview2: this.$axios.defaults.resourceURL + "images/img_upload.png",
				nickname:'',
				mobile:'',
				minDate: new Date(1930, 1, 1),
				maxDate: new Date(2040, 10, 1),
				otext:'',
				ptext:'',
				itext:'',
				ctext:'',
				ishow:false,
				oshow:false,
				pshow:false,
				cshow:false,
				ashow:false,
				bshow:false,
				eshow:false,
        olist:[],
        plist:[],
        clist:[],
				alist:['0-15000','15000-50000','50000-100000','100000-300000','300000以上'],
				ilist:[{text:'身份证',value:'national-id'},{text:'护照',value:'passport'}],
				info:{
					token:this.$route.query.token,
					first_name_cn:'',
					last_name_cn:'',
					first_name_en:'',
					last_name_en:'',
					birthday:'',
					annual_income:'',
					country:'',
					occupation:'',
					position:'',
					email:'',
					expiry_date:'',
					id_type:'',
					number:'',
					front:'',
					back:'',
					card_type:this.$route.query.card_type
				},
				fileList: [],
				fileList2: [],
			}
		},
		mounted() {
			if (this.$route.query.token == "" || this.$route.query.token == undefined) {
				this.$router.replace('/nologin')
			}
			// window.set_pic = this.set_pic
			window.set_qrcode_pic = this.set_qrcode_pic
			this.getInit()
      

		},
		methods: {
			qrcodeUpload(type) {
        this.imgtype=type;
				if (window.JsObject != null) {
					// window.JsObject.normalImageUpload();
					window.JsObject.usdtQRImageUpload()
				}
				
				if (window.webkit != null) {
					window.webkit.messageHandlers.jsToOcWithPrams.postMessage({"camera": "normal"});
				}
			},
      // set_pic(path) {
			set_qrcode_pic(path){
        var type=this.imgtype;
        if(type==1){
          this.info.front=this.$axios.defaults.imgURL + path
        }else{
          this.info.back=this.$axios.defaults.imgURL + path
        }
				this.$axios({
					method: 'post',
					url: 'rss_deposit/resize', 
					data: {
						token: this.$route.query.token,
						image: path
					},
				}).then ((res) => {
					if (res.success) {
            if(type==1){
              this.image_preview1=res.data.preview
            }else{
              this.image_preview2=res.data.preview
            }
					} else {
						this.$dialog.alert({
							message: res.error_text
						})
					}
				})
        
			},
			afterRead(file) {
				// 此时可以自行将文件上传至服务器
				console.log(file)
				// this.info.front=file.content;
				// console.log(this.fileList)
				// 这里可以添加上传到服务器的逻辑
				// 例如使用 FileReader 读取文件并显示
				// const reader = new FileReader();
				// reader.onload = (e) => {
				// 	file.url = e.target.result;
				// };
				// reader.readAsDataURL(file.file);
			},
			afterRead2(file) {
				console.log(file)
				// this.info.back=file.content;
			},
			// 获取信息
      getInit(){
        this.$axios({
					method: 'post',
					url: 'speedpay_user/registInit',
					data: {
            token: this.$route.query.token,
						card_type:this.$route.query.card_type
          }
				}).then ((res) => {
					if (res.success) {
						this.mobile=res.data.mobile;
						this.nickname=res.data.nickname;
						this.info.first_name_cn=res.data.first_name_cn;
						this.info.last_name_cn=res.data.last_name_cn;
						this.info.first_name_en=res.data.first_name_en;
						this.info.last_name_en=res.data.last_name_en;
						this.info.birthday=res.data.birthday;
						this.info.email=res.data.email;
						this.info.id_type=res.data.id_type;
						this.info.number=res.data.number;
						this.info.country=res.data.country;
						this.info.expiry_date=res.data.expiry_date;
						this.info.occupation=res.data.occupation;
						this.info.position=res.data.position;
						this.info.annual_income=res.data.annual_income;
						this.info.front=res.data.front;
						this.info.back=res.data.back;
						if(res.data.front!=''){
							this.image_preview1=res.data.front;
						}
						if(res.data.back!=''){
							this.image_preview2=res.data.back;
						}
						
						if(this.info.id_type=='national-id'){
							this.itext='身份证'
						}else if(this.info.id_type=='passport'){
							this.itext='护照'
						}
						this.getOlist()
						this.getPlist()
						this.getClist()
					}else {
						this.$dialog.alert({
							message: res.error_text
						})
					}
				})
      },
      postPapers(){
				if (this.info.last_name_cn == ""|| this.info.first_name_cn == "") {
					this.$dialog.alert({
						message: '请输入中文姓名'
					})
					return false
				}
				if (this.info.last_name_en == ""|| this.info.first_name_en == "") {
					this.$dialog.alert({
						message: '请输入英文姓名'
					})
					return false
				}
				if (this.info.birthday == "") {
					this.$dialog.alert({
						message: '请选择生日'
					})
					return false
				}
				if (this.info.annual_income == "") {
					this.$dialog.alert({
						message: '请选择年收入'
					})
					return false
				}
				
				if (this.info.occupation == "") {
					this.$dialog.alert({
						message: '请选择职业'
					})
					return false
				}
				if (this.info.position == "") {
					this.$dialog.alert({
						message: '请选择职位'
					})
					return false
				}
				if (this.info.email == "") {
					this.$dialog.alert({
						message: '请输入邮箱'
					})
					return false
				}
				if(this.validateEmail(this.info.email)==false){
          this.$dialog.alert({
						message: '请输入正确的邮箱'
					})
          return;
        }
				if (this.info.id_type == "") {
					this.$dialog.alert({
						message: '请选择证件类型'
					})
					return false
				}
				if (this.info.front == "") {
					this.$dialog.alert({
						message: '请上传证件正面照片'
					})
					return false
				}
				if (this.info.back == "") {
					this.$dialog.alert({
						message: '上传证件反面照片'
					})
					return false
				}
				if (this.info.number == "") {
					this.$dialog.alert({
						message: '请输入证件号码'
					})
					return false
				}
				if (this.info.country == "") {
					this.$dialog.alert({
						message: '请选择证件所属国家'
					})
					return false
				}
				if (this.info.expiry_date == "") {
					this.$dialog.alert({
						message: '请选择证件有效期'
					})
					return false
				}
				this.$axios({
					method: 'post',
					url: 'speedpay_user/regist',
					data: this.info
				}).then ((res) => {
					if (res.success) {
						this.$router.replace('/speedpay/step4?token='+this.$route.query.token +'&card_type='+this.$route.query.card_type)
					}else {
						this.$dialog.alert({
							message: res.error_text
						})
						setTimeout(()=>{
							this.$router.replace('/speedpay/index?token='+this.$route.query.token +'&card_type='+this.$route.query.card_type)
						},2000)
					}
				})
			},
			oOnConfirm(e){
				this.otext=e.text
				this.info.position=e.value
				this.oshow=false
			},
			pOnConfirm(e){
				this.ptext=e.text
				this.info.occupation=e.value
				this.pshow=false
			},
			cOnConfirm(e){
				this.info.country=e.value
				this.ctext=e.text;
				this.cshow=false
			},
			aOnConfirm(e){
				this.info.annual_income=e
				this.ashow=false
			},
			bOnConfirm(e){
				this.info.birthday=this.formatDate(e)
				this.bshow=false
			},
			eOnConfirm(e){
				this.info.expiry_date=this.formatDate(e)
				this.eshow=false
			},
			iOnConfirm(e){
				this.itext=e.text
				this.info.id_type=e.value
				this.ishow=false
			},
      // 职位
      getOlist(){
        this.$axios({
					method: 'post',
					url: 'speedpay_common/olist', 
				}).then ((res) => {
					if (res.success) {
						let arr=[];
						res.data.forEach(e => {
							arr.push({text:e.position_cn,value:e.id})
						});
						this.olist=arr;
						this.isOlist();
					}else {
						this.$dialog.alert({
							message: res.error_text
						})
					}
				})
      },
			// 职业
      getPlist(){
        this.$axios({
					method: 'post',
					url: 'speedpay_common/plist', 
				}).then ((res) => {
					if (res.success) {
            let arr=[];
						res.data.forEach(e => {
							arr.push({text:e.occupation_cn,value:e.id})
						});
						this.plist=arr;
						this.isPlist();
					}else {
						this.$dialog.alert({
							message: res.error_text
						})
					}
				})
      },
			// 国家
      getClist(){
        this.$axios({
					method: 'post',
					url: 'speedpay_common/clist', 
				}).then ((res) => {
					if (res.success) {
            let arr=[];
						for(var i in res.data){
							arr.push({text:i,value:res.data[i]})
						}
						// res.data.forEach(e => {
						// 	console.log(e)
						// 	arr.push({text:e.occupation_cn,value:e.id})
						// });
						this.clist=arr;
						this.isClist()
					}else {
						this.$dialog.alert({
							message: res.error_text
						})
					}
				})
      },
      formatDate(date){
				let m,d;
				if(date.getMonth()<9){
					m='0'+(date.getMonth()+1)
				}else{
					m=date.getMonth()+1
				}
				if(date.getDate()<10){
					d='0'+date.getDate()
				}else{
					d=date.getDate()
				}
				return `${date.getFullYear()}-${m}-${d}`
			},
			validateEmail(email) {
					var emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
					return emailRegex.test(email);
			},
			isOlist(){
				this.olist.forEach(e => {
					if (e.value == this.info.position) {
						this.otext=e.text
					}
				});
			},
			isPlist(){
				this.plist.forEach(e => {
					if (e.value == this.info.occupation) {
						this.ptext=e.text
					}
				});
			},
			isClist(){
				this.clist.forEach(e => {
					if (e.value == this.info.country) {
						this.ctext=e.text
					}
				});
			},
			
		},
	}
</script>
<style scoped>
.title{
  font-weight: bold;
  text-align: center;
  padding: 15px 0;
  text-align: center;
  background: #fff;
  margin-bottom: 10px;
}
  .zj{
		background: white;
		padding: 0 20px;
	}
	.zjname{
		font-size: 16px;
		color: #646566;
		padding: 15px 0px;
	}
</style>
